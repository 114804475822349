.Wraper {
  width: 800px;
  height: 100%;
}
.flex-col-c {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.flex_c {
  display: flex;
  flex-direction: column;
}

.border-t {
  border-top: 1px solid #000
}
.border-r {
  border-right: 1px solid #000
}
.border-b {
  border-bottom: 1px solid #000
}
.border-l {
  border-left: 1px solid #000
}
.border-2-t {
  border-top: 2px solid #000;
}
.border-2-r {
  border-right: 2px solid #000;
}
.border-2-b {
  border-bottom: 2px solid #000;
}
.border-2-l {
  border-left: 2px solid #000;
}


.font-14 {
  font-size: 14px;
}

.font-17 {
  font-size: 17px;
}

.Header {
  font-size: 20px;
  color: #000;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Header .Info-text {
  font-size: 24px;
}

.H3 {
  font-size: 23px;
}

.HeaderInfo {
  display: flex;
  flex-direction: row;
}

.box {
  width: 100px;
  height: 100px;
  background-color: #000;
}

.QRCode {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.woN {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.woN .title {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-col-c{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-left {
  display: flex;
  align-items: center;
  justify-content: left;
}

.woN .value {
  display: flex;
  justify-content: center;
  align-items: center;
}

.w-15 {
  width: 15%;
}

.w-16 {
  width: 16.66%;
}

.w-20 {
  width: 20%;
}

.w-22 {
  width: 20%;
}

.w-25 {
  width: 25%;
}

.w-30 {
  width :30%;
}

.w-33_5 {
  width: 33.5%;
}

.w-35 {
  width: 35%;
}

.w-40 {
  width :40%;
}

.w-50 {
  width: 50%;
}

.w-60 {
  width: 60%;
}

.w-100 {
  width: 100%;
}

.h-30 {
  height: 30px;
}

.h-50 {
  height: 40px;
}

.h-80 {
  height: 80px;
}

.h-100 {
  height: 100px;
}

.h-120 {
  height: 120px;
}

.pt-5 {
  padding-top: 5px;
}

.pr-5 {
  padding-right: 5px;
}

.pb-5 {
  padding-bottom: 5px;
}

.pl-5 {
  padding-left: 5px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pl-10 {
  padding-left: 10px;
}

.pt-10 {
  padding-top: 10px;
}

.pl-15 {
  padding-left: 15px;
}

.pl-30 {
  padding-left: 30px;
}

.pl-40 {
  padding-left: 40px;
}

.pl-70p {
  padding-left: 70%;
}

.pr-10 {
  padding-right: 10px;
}

.pr-15 {
  padding-right: 15px;
}

.mr-10 {
  margin-right: 10px;
}

.ml-30 {
  margin-left: 30px;
}

.mr-30p {
  margin-right: 30%;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.title {
  font-size: 17px;
  /* font-weight: 900; */
  /* padding:10px; */

}

.title1 {
  font-size: 17px;
  /* font-weight: 900; */
  color:#000
}

.PN {
  display: flex;

}

.PN .title {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.PN .value {
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pdtNM {
  display: flex;
}

.pdtNM .title {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pdtNM .value {
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ws {
  display: flex;
  /* align-items: center; */
  /* background-color: pink; */
}

.ws .title {
  width: 50%;
  text-align: center;
}

.ws .value {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wl {
  display: flex;
  align-items: center;
}

.wl .title {
  width: 50%;
  text-align: center;
}

.wl .value {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-r {
  display:flex;
  flex-direction: row;
}

.flex-c {
  display:flex;
  flex-direction: column;
}

.secInfo {
  display: flex;
  flex-direction: row;
}

.left {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.right {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.List {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.List > div {
  width: 10%;
}

.MaterialText {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.MaterialRight {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.flex-g {
  flex-grow: 1;
}

.ml-1x {
  margin-left: -1px
}

.ml-1 {
  margin-left: 1px
}

.Log {
  display: flex;
  justify-content: flex-start;
  /* align-items: center; */
}

.LogData {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}