@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+TC&display=swap);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont,'Noto Sans TC', sans-serif, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
}

/* antd message 變更 */
.ant-message {
  font-size:23px !important;
  color: #595959 !important;
}
.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
  z-index: 9999;
}

.row-dragging td {
  padding: 8px;
}

.row-dragging .drag-visible {
  visibility: visible;
}
.Wraper {
  width: 800px;
  height: 100%;
}
.flex-col-c {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.flex_c {
  display: flex;
  flex-direction: column;
}

.border-t {
  border-top: 1px solid #000
}
.border-r {
  border-right: 1px solid #000
}
.border-b {
  border-bottom: 1px solid #000
}
.border-l {
  border-left: 1px solid #000
}
.border-2-t {
  border-top: 2px solid #000;
}
.border-2-r {
  border-right: 2px solid #000;
}
.border-2-b {
  border-bottom: 2px solid #000;
}
.border-2-l {
  border-left: 2px solid #000;
}


.font-14 {
  font-size: 14px;
}

.font-17 {
  font-size: 17px;
}

.Header {
  font-size: 20px;
  color: #000;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Header .Info-text {
  font-size: 24px;
}

.H3 {
  font-size: 23px;
}

.HeaderInfo {
  display: flex;
  flex-direction: row;
}

.box {
  width: 100px;
  height: 100px;
  background-color: #000;
}

.QRCode {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.woN {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.woN .title {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-col-c{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-left {
  display: flex;
  align-items: center;
  justify-content: left;
}

.woN .value {
  display: flex;
  justify-content: center;
  align-items: center;
}

.w-15 {
  width: 15%;
}

.w-16 {
  width: 16.66%;
}

.w-20 {
  width: 20%;
}

.w-22 {
  width: 20%;
}

.w-25 {
  width: 25%;
}

.w-30 {
  width :30%;
}

.w-33_5 {
  width: 33.5%;
}

.w-35 {
  width: 35%;
}

.w-40 {
  width :40%;
}

.w-50 {
  width: 50%;
}

.w-60 {
  width: 60%;
}

.w-100 {
  width: 100%;
}

.h-30 {
  height: 30px;
}

.h-50 {
  height: 40px;
}

.h-80 {
  height: 80px;
}

.h-100 {
  height: 100px;
}

.h-120 {
  height: 120px;
}

.pt-5 {
  padding-top: 5px;
}

.pr-5 {
  padding-right: 5px;
}

.pb-5 {
  padding-bottom: 5px;
}

.pl-5 {
  padding-left: 5px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pl-10 {
  padding-left: 10px;
}

.pt-10 {
  padding-top: 10px;
}

.pl-15 {
  padding-left: 15px;
}

.pl-30 {
  padding-left: 30px;
}

.pl-40 {
  padding-left: 40px;
}

.pl-70p {
  padding-left: 70%;
}

.pr-10 {
  padding-right: 10px;
}

.pr-15 {
  padding-right: 15px;
}

.mr-10 {
  margin-right: 10px;
}

.ml-30 {
  margin-left: 30px;
}

.mr-30p {
  margin-right: 30%;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.title {
  font-size: 17px;
  /* font-weight: 900; */
  /* padding:10px; */

}

.title1 {
  font-size: 17px;
  /* font-weight: 900; */
  color:#000
}

.PN {
  display: flex;

}

.PN .title {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.PN .value {
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pdtNM {
  display: flex;
}

.pdtNM .title {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pdtNM .value {
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ws {
  display: flex;
  /* align-items: center; */
  /* background-color: pink; */
}

.ws .title {
  width: 50%;
  text-align: center;
}

.ws .value {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wl {
  display: flex;
  align-items: center;
}

.wl .title {
  width: 50%;
  text-align: center;
}

.wl .value {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-r {
  display:flex;
  flex-direction: row;
}

.flex-c {
  display:flex;
  flex-direction: column;
}

.secInfo {
  display: flex;
  flex-direction: row;
}

.left {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.right {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.List {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.List > div {
  width: 10%;
}

.MaterialText {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.MaterialRight {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.flex-g {
  flex-grow: 1;
}

.ml-1x {
  margin-left: -1px
}

.ml-1 {
  margin-left: 1px
}

.Log {
  display: flex;
  justify-content: flex-start;
  /* align-items: center; */
}

.LogData {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.barcord-print{
 /* margin: 5px 0px 0px 20px; */
 padding: 0;
 height: 100px;
 width: 240px;
}
img{
  max-width: 100%;
  max-height: 100%;
}
.barcord-img {
  /* height: 50px;
  width: 95px; */
  /* margin-top:5px; */
  height: 50%;
  width: 100%;
  /* background-repeat: no-repeat;
  background-size: contain;
  background-position: center; */
}
.print .color-black-2 {
  color: rgba(0, 0, 0, 0.1);
}

.print .text-align-center{
  text-align: center;
}

.print .flex-col-c {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.print .Wraper {
  width: 800px;
  height: 100%;
}

.print .flex-wrap {
  flex-wrap:wrap
}

.print .flex_c {
  display: flex;
  flex-direction: column;
}

.print .border-2-t {
  border-top: 2px solid #000;
}
.print .border-2-r {
  border-right: 2px solid #000;
}
.print .border-2-b {
  border-bottom: 2px solid #000;
}
.print .border-2-l {
  border-left: 2px solid #000;
}

.print .border-3-t {
  border-top: 3px solid #000;
}
.print .border-3-r {
  border-right: 3px solid #000;
}
.print .border-3-b {
  border-bottom: 3px solid #000;
}
.print .border-3-l {
  border-left: 3px solid #000;
}

.print .border-2-l-d {
  border-left: 2px dashed #000;
}

.print .border-t {
  border-top: 1px solid #000;
}
.print .border-r {
  border-right: 1px solid #000;
}
.print .border-b {
  border-bottom: 1px solid #000;
}
.print .border-l {
  border-left: 1px solid #000;
}
.print .font-11 {
  font-size: 11px;
}

.print .font-14 {
  font-size: 14px;
}

.print .font-17 {
  font-size: 17px;
}
.print .font-18 {
  font-size: 18px;
}

.print .font-24 {
  font-size: 24px;
}

.print .font-30 {
  font-size: 30px;
}

.print .Header {
  font-size: 20px;
  color: #000;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.print .H3 {
  font-size: 23px;
}

.print .HeaderInfo {
  display: flex;
  flex-direction: row;
}

.print .box {
  width: 100px;
  height: 100px;
  background-color: #000;
}

.print .QRCode {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.print .woN {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.print .woN .print .title {
  display: flex;
  justify-content: center;
  align-items: center;
}

.print .flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.print .flex-left {
  display: flex;
  align-items: center;
  justify-content: left;
}

.print .flex-right {
  display: flex;
  align-items: center;
  justify-content: right;
}

.print .woN .print .value {
  display: flex;
  justify-content: center;
  align-items: center;
}

.print .w-10 {
  width: 10%;
}

.print .w-15 {
  width: 15%;
}

.print .w-16_3 {
  width: 16.33333%;
}

.print .w-16_4 {
  width: 16.4%;
}

.print .w-16_75 {
  width: 16.75%;
}

.print .w-17 {
  width: 17%;
}

.print .w-17_15 {
  width: 17.15%;
}

.print .w-19 {
  width: 19%;
}

.print .w-20 {
  width: 20%;
}

.print .w-25 {
  width: 25%;
}

.print .w-26 {
  width: 26%;
}

.print .w-30 {
  width: 30%;
}

.print .w-33 {
  width: 33%;
}

.print .w-34 {
  width: 34%;
}

.print .w-34_5 {
  width: 34.5%;
}

.print .w-35 {
  width: 35%;
}

.print .w-36_3 {
  width: 36.2%;
}

.print .w-40 {
  width: 40%;
}


.print .w-50 {
  width: 50%;
}

.print .w-56 {
  width: 56%;
}

.print .w-60 {
  width: 60%;
}

.print .w-66 {
  width: 66%;
}

.print .w-70 {
  width: 70%;
}

.print .w-75 {
  width: 75%;
}

.print .w-80 {
  width: 80%;
}

.print .w-83_7 {
  width: 83.7%;
}

.print .w-100 {
  width: 100%;
}

.print .w-400p {
  width: 400px;
}
.print .w-500p {
  width: 500px;
}
.print .w-800p {
  width: 800px;
}
.print .w-1000p {
  width: 1000px;
}

.print .h-23 {
  height: 23px;
}

.print .h-25 {
  height: 25px;
}

.print .h-30 {
  height: 30px;
}

.print .h-46 {
  height: 46px;
}

.print .h-50 {
  height: 50px;
}

.print .h-40 {
  height: 40px;
}
.print .h-49 {
  height: 49px;
}

.print .h-60 {
  height: 60px;
}

.print .h-70 {
  height: 70px;
}

.print .h-80 {
  height: 80px;
}

.print .h-100 {
  height: 100px;
}

.print .h-110 {
  height: 110px;
}

.print .h-120 {
  height: 120px;
}

.print .h-150 {
  height: 150px;
}

.print .h-160 {
  height: 160px;
}
.print .h-170 {
  height: 170px;
}

.print .h-300 {
  height: 300px;
}

.print .h-538 {
  height: 538px;
}


.print .pt-5 {
  padding-top: 5px;
}

.print .pr-5 {
  padding-right: 5px;
}

.print .pb-5 {
  padding-bottom: 5px;
}

.print .pl-5 {
  padding-left: 5px;
}

.print .pl-10 {
  padding-left: 10px;
}

.print .pt-10 {
  padding-top: 10px;
}

.print .pl-15 {
  padding-left: 15px;
}

.print .pl-30 {
  padding-left: 30px;
}

.print .pl-40 {
  padding-left: 40px;
}

.print .pr-10 {
  padding-right: 10px;
}

.print .pr-15 {
  padding-right: 10px;
}


.print .mr-10 {
  margin-right: 10px;
}

.print .ml-5 {
  margin-left: 5px;
}

.print .ml-10 {
  margin-left: 10px;
}

.print .ml-30 {
  margin-left: 30px;
}

.print .mr-30p {
  margin-right: 30%;
}

.print .mt-10 {
  margin-top: 10px;
}

.print .mt-20 {
  margin-top: 20px;
}

.print .mt-30 {
  margin-top: 30px;
}

.print .title {
  font-size: 17px;
  /* font-weight: 900; */
  /* padding:10px; */
}

.print .break {
  word-break: break-all;
}

.print .title1 {
  font-size: 17px;
  /* font-weight: 900; */
  color: #000;
}

.print .PN {
  display: flex;
}

.print .PN .print .title {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.print .PN .print .value {
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.print .pdtNM {
  display: flex;
}

.print .pdtNM .print .title {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.print .pdtNM .print .value {
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.print .ws {
  display: flex;
  /* align-items: center; */
  /* background-color: pink; */
}

.print .ws .print .title {
  width: 50%;
  text-align: center;
}

.print .ws .print .value {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.print .wl {
  display: flex;
  align-items: center;
}

.print .wl .print .title {
  width: 50%;
  text-align: center;
}

.print .wl .print .value {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.print .flex-r {
  display: flex;
  flex-direction: row;
}

.print .flex-c {
  display: flex;
  flex-direction: column;
}

.print .secInfo {
  display: flex;
  flex-direction: row;
}

.print .left {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.print .right {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.print .List {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.print .List > div {
  width: 10%;
}

.print .MaterialText {
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.print .MaterialRight {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.print .flex-g {
  flex-grow: 1;
}

.print .ml-1x {
  margin-left: -1px;
}

.print .ml-1 {
  margin-left: 1px;
}

.print .Log {
  display: flex;
  justify-content: flex-start;
  /* align-items: center; */
}

.print .LogData {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

